import { useContext } from "react";
import { AliasContext } from "../../components/context/AliasContext";
import Cooperate from "../../components/cooperate";
import Header from "./header";
import Integration from "./integration";
import Models from "./models";
import Platform from "./platform";
import { Helmet } from "react-helmet";

export default function Publishers() {

    const { alias } = useContext(AliasContext)

    return (
        <>
            <Helmet><title>{alias === "admatic" ? 'AdMatic' : 'Pixad'} - Publishers</title></Helmet>
            <Header />
            <Platform />
            <Integration />
            <Models />
            <Cooperate bgClass="secondary" />
        </>
    )
}