import Wrap from '../../../../components/wrap'
import { useContext } from 'react'
import pixadNumbers from '../../../../assets/imgs/pixad-numbers.png'
import admaticNumbers from '../../../../assets/imgs/admatic-numbers.jpg'
import { useMediaQuery } from 'react-responsive';
import { AliasContext } from '../../../../components/context/AliasContext';

export default function Coverage() {

    const { alias } = useContext(AliasContext)

    const isMobile = useMediaQuery({
        query: '(max-width: 1024px)',
    });

    /*useEffect(()=> {
        let valueDisplays = document.querySelectorAll(".num");
        let interval = 1000;
        valueDisplays.forEach((valueDisplay) => {
        let startValue = 0;
        let endValue = parseInt(valueDisplay.getAttribute("data-val"));
        let duration = Math.floor(interval / endValue);
        let counter = setInterval(function () {
            startValue += 0.1;
            valueDisplay.textContent = startValue;
            if (startValue == endValue) {
            clearInterval(counter);
            }
        }, duration);
        });
    })*/

    return (
        <section className={`coverage-section ${alias}`}>
            <Wrap>
                <div className="coverage-bg">
                    <div className="text-wrapper">
                        <h2 className="section-title">{alias === "admatic" ? 'ADMATIC' : 'PIXAD'} IN FIGURES</h2>
                        <p className="section-desc">{alias === "admatic" ? 'AdMatic' : 'Pixad'} has rapidly reached the milestone of 1 billion monthly ad impressions.</p>
                    </div>
                    {isMobile && <img src={alias === "admatic" ? admaticNumbers : pixadNumbers} />}
                    <div className="circles">
                        <div className="circle">
                            <p className="circle-title"><span className="num" data-val="1.2">1.2</span>B+</p>
                            <p className="circle-desc">ad impressions monthly</p>
                        </div>
                        <div className="circle">
                            <p className="circle-title"><span className="num" data-val="4.1">4.1</span>B+</p>
                            <p className="circle-desc">unique visitors monthly</p>
                        </div>
                    </div>
                    <div className="coverage-footer">
                        <h2 className="section-title">Chosen by the Finest Across the Globe</h2>
                        <p className="section-desc">From Beloved Local Brands to Internationally Acclaimed Enterprises: Embraced by All</p>
                    </div>
                </div>
            </Wrap>
        </section>
    )
}