import { Helmet } from "react-helmet";
import Cooperate from "../../components/cooperate";
import Header from "./header";
import Integration from "./integration";
import Models from "./models";
import Platform from "./platform";
import { useContext } from "react";
import { AliasContext } from "../../components/context/AliasContext";

export default function Advertisers() {

    const { alias } = useContext(AliasContext)
    
    return (
        <>
            <Helmet><title>{alias === "admatic" ? 'AdMatic' : 'Pixad'} - Advertisers</title></Helmet>
            <Header />
            <Platform />
            <Integration />
            <Models />
            <Cooperate />
        </>
    )
}