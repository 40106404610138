import { RouterProvider } from "react-router-dom";
import router from "./routes";
import { AliasContext } from "./components/context/AliasContext";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";
import Loading from "./components/loading";
import CookieConsent from "react-cookie-consent";
import Cookies from "js-cookie";

function App({ history }) {
  let host;
  const [alias, setAlias] = useState(null);
  const [iframe, setIframe] = useState(null);

  const [cookiePermission, setCookiePermission] = useState(
    () => Cookies.get("adm_cookie_permission") || "1"
  );

  useEffect(() => {
    host = window.location.host;

    if (host.includes("admatic") || host.includes("localhost")) {
      setAlias("admatic");
      //ReactGA.initialize('G-4VB831D7X5');
    } else if (host.includes("pixad")) {
      setAlias("pixad");
      //ReactGA.initialize('G-H97Z2CF1WF');
    }

    if (cookiePermission === "1") {
      const newIframe = document.createElement("iframe");
      if (alias !== null) {
        if (alias === "admatic") {
          newIframe.src = "https://static.admatic.de/sync.html";
        } else {
          newIframe.src = "https://static.pixad.com.tr/sync.html";
        }
        newIframe.width = "0";
        newIframe.height = "0";
        newIframe.style.display = "none";
        document.body.appendChild(newIframe);
        setIframe(newIframe);
      }
    } else {
      if (iframe) {
        document.body.removeChild(iframe);
        setIframe(null);
      }

      if (alias !== null) {
        if (alias === "admatic") {
          Cookies.remove("uids", { domain: ".admatic.de", path: "/" });
        } else {
          Cookies.remove("uids", { domain: ".pixad.com.tr", path: "/" });
        }
      }
    }
  }, [alias, cookiePermission]);

  if (alias == null) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Helmet>
          <title>Loading...</title>
        </Helmet>
        <Loading />
      </div>
    );
  }

  return (
    <AliasContext.Provider
      value={{ alias, cookiePermission, setCookiePermission }}
    >
      <CookieConsent
        location="bottom"
        buttonText="Kabul Et"
        declineButtonText="Reddet"
        enableDeclineButton
        flipButtons
        style={{ background: "#2B373B" }}
        buttonStyle={{
          color: "#fff",
          fontSize: "13px",
          backgroundColor: "#ac1a17",
        }}
        declineButtonStyle={{
          color: "#ac1a17",
          backgroundColor: "transparent",
          borderColor: "#ac1a17",
          borderWidth: 2,
          borderStyle: "solid",
        }}
      >
        Bu site çerezleri kullanmaktadır. Kabul ederek, çerez politikamızı kabul
        etmiş olursunuz.
      </CookieConsent>
      <Helmet>
        <title>{alias === "admatic" ? "AdMatic" : "Pixad"}</title>
        <meta
          name="description"
          content={
            alias === "admatic"
              ? "AdMatic is a creative powerhouse!"
              : "Pixad is a creative powerhouse!"
          }
        />
        <link
          rel="icon"
          href={
            alias === "admatic" ? "admatic-favicon.png" : "pixad-favicon.svg"
          }
        />
      </Helmet>
      <RouterProvider router={router} />
    </AliasContext.Provider>
  );
}

export default App;
