import { useContext } from "react"
import { AliasContext } from "../../../components/context/AliasContext"

export default function Header() {

    const { alias } = useContext(AliasContext)

    return (
        <div className="in-page__hero">
            <div>
                <div className={`page-bg ${alias === "admatic" ? 'after:bg-admatic-advertisers-hero' : 'after:bg-pixad-advertisers-hero'} `}>
                    <h1 className="hero__title">Advertisers</h1>
                    <p className="section-desc white">{alias === "admatic" ? 'AdMatic' : 'Pixad'} offers advertisers the opportunity to reach their target audience more accurately, while providing publishers with the chance to increase their advertising revenues.</p>
                </div>
            </div>
        </div>
    )
}