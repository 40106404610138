import { Link } from 'react-router-dom'
import Wrap from '../../../../components/wrap'
import { useContext } from 'react'
import { AliasContext } from '../../../../components/context/AliasContext'

export default function Vision() {

    const { alias } = useContext(AliasContext)

    return (
        <section id="vision" className="vision-section">
            <Wrap>
                <div>
                    <h2 className="section-title">{alias === "admatic" ? 'AdMatic' : 'Pixad'} Vision</h2>
                    <p className="section-desc">Advertising exchanges, developed to enhance interaction between advertisers and publishers and to optimize advertising revenues, have become key players in digital marketing. <br />
                        {alias === "admatic" ? 'AdMatic' : 'Pixad'} is an online platform that unifies the digital advertising space into a marketplace for buyers (advertisers) and sellers (publishers). This platform enables advertisers to purchase ad placements that are directed towards content where they want to reach their target audience. Publishers, on the other hand, generate revenue by selling their ad spaces through methods such as auctions or programmatic buying.
                    </p>
                </div>
            </Wrap>
        </section>
    )
}