import { useContext } from "react";
import { AliasContext } from "../../components/context/AliasContext";
import Wrap from "../../components/wrap";

export default function Agreement() {

    const { alias } = useContext(AliasContext)

    return (
        <section className="user-agreement">
            <div>
                {alias === "pixad" &&
                    <Wrap>
                        <h3>USER and Service Agreement</h3>
                        <h4>PIXAD AD PUBLISHING AGREEMENT</h4>
                        <p>This Ad Publishing Agreement (hereinafter referred to as the “Agreement”) is between ’PIXAD MEDYA A.Ş (hereinafter referred to as the “PIXAD”) and PUBLISHER OF PIXAD MEDYA A.Ş. (hereinafter referred to as the “PUBLISHER”), in case of accepting agreement on electronic platform, agreement shall be in force intending to be legally bound hereby.</p>
                        <p>PUBLISHER and PIXAD will be referred as “Party” if indicated separately, “Parties” if indicated together.</p>
                        <h5>ARTICLE 1</h5>
                        <h6>SUBJECT OF AGREEMENT</h6>
                        <p>Agreement is about details of ad services developed and administrated by PIXAD and defining rights and obligations of PIXAD and PUBLISHER mutually.</p>
                        <h5>ARTICLE 2</h5>
                        <h6>SERVICE SETUP</h6>
                        <p>2.1 PUBLISHER agrees to perform technical requirements and terms of specifications. These terms of technical specifications may include following rules;</p>
                        <p>2.2 PUBLISHER agrees and warrants to set codes provided by PIXAD that enable ad campaigns to be published in PUBLISHER’s web page to his/her/its own web page.</p>
                        <p>2.3 PUBLISHER agrees and warrants that he/she/it will not attempt to interfere with functions of ad service or use any application and/or device that may damage or prevent system functions.</p>
                        <p>2.4 PUBLISHER agrees and warrants that he/she/it will not interfere the system contrary to law or terms of agreement, assault and act with unlawful purpose and doing actions such as unauthorized access at electronical media.</p>
                        <p>2.5 PUBLISHER agrees and acknowledges that he/she/it will not manipulate the system by counterfeit clicks or projections to provide side income, if such clicks are detected he/she/it waives all rights to claim whether arising or not and loses all PUBLISHER privileges.</p>
                        <p>2.6 PUBLISHER agrees and acknowledges that all info set in membership information belonged to him/her/it shall be accurate and upon changes thereof, PUBLISHER shall immediately update all required information and that he/she/it is liable for any damages that may arise from inaccurate or lacking information</p>
                        <h5>ARTICLE 3</h5>
                        <h6>AD DISPLAY</h6>
                        <p>3.1 PUBLISHER may deactivate and/or remove PIXAD codes/ads in his/her/its web page at any time. PUBLISHER has right to receive/publish ads by advertising agencies, advertisers or third-party advertisers without requirement of excuse.</p>
                        <p>3.2 PIXAD uses commercially reasonable effort to not publish defamatory, obscene or illegal ad campaigns that may harm PUBLISHER. In case of projecting such ads on web page of PUBLISHER, PIXAD is obligated to remove such ads from web page of PUBLISHER immediately, if PIXAD is informed orally or in written.</p>
                        <h5>ARTICLE 4</h5>
                        <h6>USER REPORT PANEL</h6>
                        <p>PIXAD, through its own servers, measures view count and/or clicks and/or other standards required to calculate allowance amount of PUBLISHER  </p>
                        <p>PUBLISHER agrees that PIXAD’s measurements are final and superior to any other measurements. PIXAD shall provide access PUBLISHER to an online user panel in order to check her/his/its allowance account and access daily statistic. PUBLISHER shall be liable to protect and use her/his/its personal data and password, and PUBLISHER shall inform PIXAD in written immediately in case of loss or disclosure of data or password. </p>
                        <h5>ARTICLE 5</h5>
                        <h6>INVOICES AND PAYMENT</h6>
                        <p>5.1 PUBLISHER’s invoice allowance amount, agreements and issuing invoices shall be in monthly periods.</p>
                        <p>5.2  PIXAD shall pay the amount of invoice within 30 days from the day invoice is issued</p>
                        <p>5.3  PIXAD shall remit due payment of PUBLISHER to IBAN that PUBLISHER acknowledged on the invoice and/or on online user panel provided by PIXAD</p>
                        <h5>ARTICLE 6</h5>
                        <h6>INTELLECTUAL PROPERTY</h6>
                        <p>6.1 Parties retains all right, title and interest in and to, and all Intellectual Property Rights belonged to Parties before effective day of Agreement.</p>
                        <p>6.2      All Intellectual Property, including but not limited to, codes provided by PIXAD to PUBLISHER, designs are property of PIXAD or PIXAD Advertisers. PIXAD or PIXAD Advertisers authorize PUBLISHER to use works subject to copyright, services and data.</p>
                        <p>6.3 Unless PIXAD gives license in written, PUBLISHER shall not copy, publish or issue or do derivative works from PIXAD’s or PIXAD Advertisers’ works subject to copyright. </p>
                        <h5>ARTICLE 7</h5>
                        <h6>VALIDITY OF PIXAD REGISTRY</h6>
                        <p>PUBLISHER agrees that PIXAD registries shall be valid, at any arising dispute all PIXAD’s registries, document and books and, computer and audio records, microfilm and microfiches shall be legally binding, sole and hard evidence, article herein shall be evidential contract in scope of Turkish Code of Civil Procedure (HMK) article no. 193.</p>
                        <h5>ARTICLE 8</h5>
                        <h6>CONTRACT PERIOD AND TERMINATION</h6>
                        <p>8.1 When Agreement shall be approved electronically, it will enter into force mutually</p>
                        <p>8.2 Either party may terminate this Agreement for any reason in accordance with terms and conditions of Agreement without requirement of excuse       </p>
                        <p>8.3 Upon termination of this Agreement, PUBLISHER may issue an invoice current allowance amount final and approved by PIXAD on online user panel to PIXAD in the end of the month following the termination date. PIXAD shall pay the amount of the invoice in due date dedicated Article 5.2 herein.</p>
                        <p></p>
                        <p></p>
                        <h5>ARTICLE 9</h5>
                        <h6>CONFIDENTIALITY, SECURITY AND PROTECTION OF PERSONAL DATA</h6>
                        <p>9.1  PIXAD uses various technologies to collect and store data on the contents of the PUBLISHER’s web page and the behavior of their visitors for the purpose of improving advertising performance. This way of working may also include using cookies or similar technologies to determine the browser, device, location, internet protocol address and behavior of the PUBLISHER’s web page users. Collected data are all anonymous and does not include personal data. Data collected by PIXAD in scope of services of PIXAD is used in order to provide, protect, perform service and develop new services, and displaying more suitable ads for PUBLISHER’s web page users.</p>
                        <p>9.2 However, in the extent of Agreement hereby if data accessed by Parties through PUBLISHER’s web page are personal data in scope of Code No. 6698;</p>
                        <p>If PUBLISHER collects third persons’ personal data by himself/herself/itself on his/her/its web page and PIXAD accesses thereto through PUBLISHER, PUBLISHER agrees that this data is shared PIXAD in extent of permits specified in legal regulations and permits shall be given to PIXAD upon request of receiving these permits. PUBLISHER agrees, acknowledges and warrants to compensate direct damages of PIXAD arising of PUBLISHER infringing obligation of having permits herein</p>
                        <p>In case of PIXAD collects personal data through PUBLISHER web page and PUBLISHER gives accession thereto this personal data, PUBLISHER agrees, acknowledges and warrants to keep this personal data secret, ensure that all PUBLISHER’s worker and including business partners shall behave in accordance confidentiality clause herein, not use and process this personal data anyhow, take any measure hereby. PUBLISHER irrevocably agrees, acknowledges and warrants to compensate any damage (whether arose or not) of PIXAD originated from infringing confidentiality obligations.</p>
                        <p>Parties agree, acknowledge and warrant that they will perform obligations ensured in Code of Protection of Personal Data Law No. 6698 and other related regulations, upon amendment thereof Parties shall meet latest requirements immediately    </p>
                        <p>PIXAD agrees, acknowledges and warrants that PIXAD shall process personal data received through web page of PUBLISHER as accordingly law and good faith, accurate, updated when required, certain, bound with reason to do explicitly and legitimately, limited and restrained and also in accordance with procedures and principles ensured in Code of Protection of Personal Data Law No. 6698, and that it shall be retained for required reasons and for period ensured Code of Protection of Personal Data Law No. 6698, and that shall take required measures to provide sufficient security level in order to secure in order to retain personal data. When required PIXAD shall receive explicit consent of related person.</p>
                        <p> ARTICLE 10</p>
                        <h6>NOTICES</h6>
                        <p>PUBLISHER agrees, acknowledges and warrants that all notices hereunder shall be given to e-mail or address registered while signing up for PIXAD user panel and shall be valid with its all legal consequences. Parties shall notice any address or e-mail change immediately. Otherwise, the notice given to the former address or e-mail shall be valid legally.</p>
                        <h5>ARTICLE 11</h5>
                        <h6>ASSINGMENT</h6>
                        <p>PIXAD has right and authority to amend articles of Agreement at any time one-sidedly. PUBLISHER agrees, acknowledges and warrants that former and current ad publishers will be bound to amendments after publishing at this page </p>
                        <h5>ARTICLE 12</h5>
                        <h6>DISPUTES RESOLUTIONS</h6>
                        <p>This Agreement is subject to Turkish law and any dispute stemming from this Agreement shall be submitted to the exclusive jurisdiction of the courts (and enforcement offices) of İstanbul Anadolu, Turkey.</p>
                        <p>As Publisher and Publisher Applicant, I read and agree this agreement consisting of 12 (twelve) articles with my own will hereby. I agree and warrant to perform obligations of Agreement.</p>
                    </Wrap>}
            </div>
        </section>
    )
}
