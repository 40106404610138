import { Link } from "react-router-dom";
import Wrap from "../wrap";
import { AliasContext } from "../context/AliasContext";
import { useContext } from "react";

export default function Cooperate({ bgClass = "primary", buttonTitle = "GET STARTED" }) {

    const { alias } = useContext(AliasContext)

    return (
        <section className={`cooperate-section ${bgClass} ${alias}`}>
            <Wrap>
                <h2 className="section-title white">Let's Start Something Remarkable Today!</h2>
                <div>
                    {alias !== "admatic" && <Link className="button-secondary" target="_blank" to={alias === 'admatic' ? 'https://apps.admatic.de/auth/sign-up' : 'https://apps.pixad.com.tr/auth/sign-up'}>{buttonTitle}</Link>}
                </div>
            </Wrap>
        </section>
    )
}