import { useContext } from "react";
import Wrap from "../../../components/wrap";
import { AliasContext } from "../../../components/context/AliasContext";

export default function Models() {

    const { alias } = useContext(AliasContext)

    return (
        <section className={`models-section ${alias === "admatic" ? 'bg-admatic-models-advertisers' : 'bg-pixad-models-advertisers'}`}>
            <Wrap>
                <div>
                    <h2 className={`section-title ${alias === "admatic" ? 'white bg-admatic-models-advertisers' : 'bg-pixad-models-advertisers'}`}>Supports All Ad Formats</h2>
                    <p className={`section-desc ${alias === "admatic" ? 'white bg-admatic-models-advertisers' : 'bg-pixad-models-advertisers text-black'}`}>Optimizes and delivers solutions for Display, In-Stream video, Out-Stream, and Native ad formats.</p>
                </div>
                <p className={`section-desc ${alias === "admatic" ? 'white bg-admatic-models-advertisers' : 'bg-pixad-models-advertisers text-black'}`}>AdMatic integrates ad delivery for display and video, multi-integration capabilities, and outstream functionality into a single platform, thereby creating a new framework for programmatic buyers to monetize their inventory.</p>
            </Wrap>
        </section>
    )
}
