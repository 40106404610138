import { useContext } from 'react'
import Wrap from '../../../components/wrap'
import { AliasContext } from '../../../components/context/AliasContext'

export default function Platform() {

    const { alias } = useContext(AliasContext)

    return (
        <section className="platform-description-section bg-platform-publishers">
            <Wrap>
                <div>
                    <h2 className="section-title">Real-Time Bidding System</h2>
                    <p className="section-desc text-black">{alias === "admatic" ? 'AdMatic' : 'Pixad'} enhances your revenue by generating real-time bids for ad spaces. It is fully integrated with all key Header Bidding solutions. The Real-Time Bidding System ensures that ad inventory is never left unsold. Instant bids rapidly fill ad spaces, boosting publishers' revenue. It offers advertisers the opportunity to display their ads in real-time to the most appropriate audience. Through data analysis and targeting capabilities, ads reach a more accurate and relevant audience. This increases ad effectiveness.</p>
                </div>
            </Wrap>
        </section>
    )
}