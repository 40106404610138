import { Navigate, createBrowserRouter } from "react-router-dom";
import Layout from "../layouts";
import Home from "../pages/home";
import Contact from "../pages/contact";
import Advertisers from "../pages/advertisers";
import Publishers from "../pages/publishers";
import SignUp from "../pages/signup";
import Agreement from "../pages/agreement";
import Optout from "../pages/opt-out";

const router = createBrowserRouter([
    {
        path: '/',
        element: <Layout />,
        children: [
            {
                index: true,
                element: <Home />
            },
            {
                path:'contact',
                element: <Contact />
            },
            {
                path:'advertisers',
                element: <Advertisers />
            },
            {
                path:'publishers',
                element: <Publishers />
            },
            {
                path:'user-agreement',
                element: <Agreement />
            },
            {
                path:'opt-out',
                element: <Optout />
            },
            /*{
                path:'signup',
                element: <SignUp />
            },*/
            {
                path: '*',
                element: <Navigate to="/"/>
            }
        ]
    }
])

export default router